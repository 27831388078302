.drilldown {
    &.is-closing {
        &, * {
            pointer-events: none;
        }
    }

    &__close {
        width: 16px;
        height: 11px;
    }
}
