/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    @responsive {

        .gpu {
            @include gpu();
        }

        .anti {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .round {
            border-radius: 100%;
        }

        .square {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
        }

        .full {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        .clear {
            &:before, &:after {
                content: "";
                display: table;
            }
            &:after {
                clear: both;
            }
        }

        .center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .center-x {
            left: 50%;
            transform: translateX(-50%);
        }

        .center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        .placed-wrap {
            position: relative;
            height: 0;
        }

        .placed-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        .fullvp {
            height: 100vh;
            @supports (--custom:property) {
                height: calc(var(--vh, 1vh) * 100);
            }
        }

        .min-fullvp {
            min-height: 100vh;
            @supports (--custom:property) {
                min-height: calc(var(--vh, 1vh) * 100);
            }
        }

        .scrollable {
            @include scrollable();
            overscroll-behavior: none;
        }

        .scrollable-x {
            @include scrollableX();
        }

        .no-scrollbar {
            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        .ellipsis {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;

            //&.link {
            //    line-height: normal;
            //}
        }

        .hit {
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                min-width: 44px;
                min-height: 44px;
                position: absolute;
                left: 50%; top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .link {
            text-decoration: underline;
            text-decoration-color: currentColor;
            transition: text-decoration-color 0.15s;
            @at-root {
                button#{&}:not([disabled]):hover,
                a[href]#{&}:hover,
                button:not([disabled]):hover &,
                a[href]:hover & {
                    @supports (not (text-decoration-color: transparent)) {
                        text-decoration: none;
                    }
                    text-decoration-color: transparent;
                }
            }
        }

        .link-alt {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.15s;
            @supports (not (text-decoration-color: transparent)) {
                text-decoration: none;
            }
            @at-root {
                button#{&}:not([disabled]):hover,
                a[href]#{&}:hover,
                button:not([disabled]):hover &,
                a[href]:hover & {
                    text-decoration: underline;
                    text-decoration-color: currentColor;
                }
            }
        }

        .bleed {
            padding-left: 0;
            padding-right: 0;
        }

        .js {
            html.no-js & {
                display: none !important;
            }
        }

        .no-js {
            html.js & {
                display: none !important;
            }
        }

        .touch {
            html.no-touch & {
                display: none !important;
            }
        }

        // Note: There's an official Tailwind 2.x/3.x plugin for line clamping but we can't use that because Tailwind 1.x!
        .clamp {
            @supports (-webkit-line-clamp: 1) {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .lines {
            @supports (-webkit-line-clamp: 1) {
                &-1 {
                    -webkit-line-clamp: 1;
                }
                &-2 {
                    -webkit-line-clamp: 2;
                }
                &-3 {
                    -webkit-line-clamp: 3;
                }
                &-4 {
                    -webkit-line-clamp: 4;
                }
                &-5 {
                    -webkit-line-clamp: 5;
                }
            }
        }

        .supports {
            &\:clamp {
                @supports not (-webkit-line-clamp: 1) {
                    display: none;
                }
            }
        }

        .hyphens {
            hyphens: auto;
        }

        .no-hyphens {
            hyphens: none;
        }

    }

}
