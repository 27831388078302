.checkbox {
    input {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        appearance: none;
    }
    svg {
        opacity: 0;
    }
    input:checked + &__box svg {
        opacity: 1;
    }
    input:focus + &__box {
        @apply bg-solsee-yellow-light invert:bg-black text-dark invert:text-white;
    }
}
