.input-text {
    appearance: none;
    @at-root {
        textarea#{&} {
            height: 112px;
            padding-top: 17px;
            resize: none;
        }
    }
}
