.hero-numbers {

    &__number {

        $this: &;

        width: auto;
        min-width: 76px;
        height: 76px;
        max-width: 100vw;
        border-radius: 71px;
        transition: background-color 0.3s;

        font-size: 70px;

        &-text {
            margin-top: -5px;

            > * {
                padding: 0 25px;
            }
        }

        &-dots {
            margin-top: -19px;
            transition: opacity 0.3s;

            [aria-expanded="true"] & {
                opacity: 0 !important;
            }
        }

        @screen m {
            width: 160px;
            height: 160px;
            border-radius: 80px;
            max-width: calc(100vw - 80px);
            font-size: 140px;

            &-text {
                margin-top: -15px;

                > * {
                    padding: 0 30px;
                }
            }

            &-dots {
                margin-top: -34px;
            }
        }

        @screen mp {
            width: 200px;
            height: 200px;
            border-radius: 100px;
            max-width: calc(100vw - 100px);
            font-size: 180px;

            &-text {
                margin-top: -16px;

                > * {
                    padding: 0 35px;
                }
            }

            &-dots {
                margin-top: -48px;
            }
        }

        @screen l {
            width: 250px;
            height: 250px;
            border-radius: 125px;
            max-width: calc(100vw - 125px);
            font-size: 220px;

            &-text {
                margin-top: -20px;

                > * {
                    padding: 0 50px;
                }
            }

            &-dots {
                margin-top: -52px;
            }
        }

        @screen lp {
            width: 300px;
            height: 300px;
            border-radius: 150px;
            max-width: calc(100vw - 150px);
            font-size: 280px;

            &-text {
                margin-top: -20px;

                > * {
                    padding: 0 70px;
                }
            }

            &-dots {
                margin-top: -78px;
            }
        }

        @screen m {
            &:hover {
                @apply bg-current;
                #{$this}-dots {
                    opacity: 1;
                }
            }
        }

        &[aria-expanded="true"] {
            @apply bg-current;
            width: auto;
        }

    }

    &__caption {

        min-height: 90px;
        transition: background-color 0.3s;

        font-size: 20px;

        padding: 16px 25px;

        @screen m {
            height: 170px;
            font-size: 40px;
        }

        @screen mp {
            height: 200px;
            font-size: 48px;
        }

        @screen l {
            height: 250px;
            font-size: 60px;
            padding: 20px 35px;
        }

        @screen lp {
            height: 300px;
            font-size: 68px;
            padding: 30px 40px;
        }

        &.is-active {
            @apply bg-current;
            @screen m {
                max-width: 66.6666%;
            }
        }
    }
}
