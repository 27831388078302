/*
==========================
Fonts
** Remember to configure font families for Tailwind in src/tailwind.config.js
==========================
 */
$fontSizeBase: 16px;
$lineHeightBase: calc(25/16);
$fontDefault: "Graphik Web", "Helvetica Neue", Helvetica, Arial, sans-serif;

/*
==========================
Colors
** Remember to configure colors for Tailwind in src/tailwind.config.js
==========================
 */
$colorText: #1D1D26; // base-dark
$colorBackground: #FFFFFF; // base-white
