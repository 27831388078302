.wysiwyg {
    p {
        margin-bottom: calc((25/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong, b {
        @apply font-medium;
    }

    h1, h2, h3, h4, h5, h6 {
        &:not(:first-child) {
            margin-top: 2em;
        }
        &:not(:last-child) {
            margin-bottom: 0.7em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    *:first-child {
        margin-top: 0;
    }

    li {
        padding-left: 1.7em;
        position: relative;
        margin-bottom: calc((32/20) * 0.5em);

        &::before {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    ul, ol {
        padding-left: 1.4em;
        &:not(:last-child) {
            margin-bottom: calc((25/16) * 1em);
        }
    }

    ul li::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        @apply bg-dark invert:bg-current;
        top: 0.45em;
    }

    ol {
        counter-reset: list;
    }

    ol li {
        counter-increment: list;
    }

    ol li::before {
        content: counter(list) ".";
    }

    ol ol li::before {
        content: counter(list, lower-alpha) ".";
    }

    li ul,
    li ol {
        padding-top: calc((32/20) * 1em);
    }

    strong, b {
        font-weight: 500;
    }

    em, i {
        font-style: italic;
    }

    a:not([class*="link"]) {
        text-decoration: underline;
        text-decoration-color: currentColor;
        transition: text-decoration-color 0.3s;
        &:hover {
            @apply text-caption-dark invert:text-caption-light;
            text-decoration-color: transparent;
            @supports (not (text-decoration-color: transparent)) {
                text-decoration: none;
            }
        }
    }
}
