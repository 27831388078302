.listbox {

    &.open {
        z-index: 5;
    }

    &__toggle {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        left: 1px; top: 1px;
        &-box {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            left: -1px; top: -1px;
        }
        &[aria-expanded] {
            background-color: transparent !important;
            svg {
                transform: scaleY(-1) translateY(3px);
            }
        }
        &[aria-expanded] &-box {
            border-color: transparent !important;
        }
    }

    &__opts {
        padding-top: 56px;
        @screen l {
            padding-top: 50px;
        }
        @screen lp {
            padding-top: 56px;
        }
        li {
            min-height: 31px;
            &[aria-selected]:not(.deselected), &.selected {
                @apply bg-solsee-yellow-light invert:bg-black text-dark invert:text-white border-inherit invert:border-black;
            }
        }
    }

}
