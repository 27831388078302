.carousel {

  &__img {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    left: -2px;
    top: -2px;
  }

  &__dot {
    width: 10px;
    height: 10px;

    &.is-active {
      opacity: 1;
    }

    @screen l {
      width: 14px;
      height: 14px;
    }

    @screen lp {
      width: 18px;
      height: 18px;
    }
  }
}
