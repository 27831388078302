.slider {
    &__slide {
        width: auto;
        height: calc((100vw - (40px + var(--scrollbar-width, 0px))) / var(--max-ratio, calc(4/3)));
        @screen m {
            height: 300px;
        }
        @screen l {
            height: 400px;
        }
        @screen lp {
            height: 500px;
        }
    }
}
