.value-prop {
    > * {
        @screen l {
            max-width: 220px;
        }
        @screen lp {
            max-width: 280px;
        }
    }
    img {
        max-width: 100%;
        height: 130px;
        @screen lp {
            height: 160px;
        }
    }
}
