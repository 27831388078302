@keyframes rotate {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.marquee {
  will-change: transform;
  animation: rotate var(--duration, 30s) infinite linear;
}
