.people {

  button[aria-pressed="true"] {
    @apply bg-solsee-green;
  }

  &__list {
    width: calc(100% + 20px);
    margin-left: -10px;
    @screen m {
      width: calc(100% + 38px);
      margin-left: -19px;
    }
    @screen l {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
    @screen lp {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  &__item {
    flex: 1 1 340px;
    max-width: 420px;
    @screen m {
      max-width: 100%;
    }
    @screen lp {
      flex: 1 1 380px;
    }
  }

  &__biobtn {
    &[aria-expanded="true"] {
      &, * {
        pointer-events: none;
      }
      html.using-touch & > * {
        display: none;
        &.avatar {
          display: block;
          pointer-events: auto;
        }
      }
    }
  }
}
