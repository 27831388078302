/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    .h1 {
        @apply text-46 leading-54 l:text-60 l:leading-70 lp:text-80 lp:leading-90 font-semibold;
    }

    .h2 {
        @apply text-30 leading-38 l:text-40 l:leading-50 lp:text-55 lp:leading-65 font-semibold;
    }

    .h3 {
        @apply text-24 leading-32 l:text-26 l:leading-34 lp:text-30 lp:leading-40 font-medium;
    }

    .h4 {
        @apply text-24 leading-34 l:text-20 l:leading-29 lp:text-24 lp:leading-34 font-medium;
    }

    .h5 {
        @apply text-18 leading-27 l:text-16 l:leading-25 lp:text-18 lp:leading-27 font-medium;
    }

    .body {
        @apply text-16 leading-25 l:text-14 l:leading-23 lp:text-17 lp:leading-27;
    }

    .caption {
        @apply text-14 leading-21 l:text-12 l:leading-19 lp:text-14 lp:leading-21;
    }

    .numbers {
        @apply text-100 l:text-80 lp:text-100 font-semibold;
    }

    .avatar {
        width: 55px;
        height: 55px;
        border-radius: 100%;
        overflow: hidden;

        @screen l {
            width: 70px;
            height: 70px;
        }

        @screen lp {
            width: 80px;
            height: 80px;
        }
    }

    .arrow {
        width: 22px;
        height: auto;
        @screen l {
            width: 31px;
        }
        @screen lp {
            width: 38px;
        }
    }

    .play {
        width: 19px;
        height: auto;
        @screen l {
            width: 27px;
        }
        @screen lp {
            width: 31px;
        }
    }

    .caret {
        width: 13px;
        height: 8px;
    }

    .input {
        height: 57px;
        @screen l {
            height: 51px;
        }
        @screen lp {
            height: 57px;
        }
    }

    .pad-top {
        @apply pt-170 lp:pt-190;
    }

    .space-bottom {
        @apply mb-80 l:mb-100 lp:mb-120;
    }

    .content-block {
        @apply mb-60 lp:mb-70;
        &.images-auto + &.images-auto {
            @apply -mt-40 m:-mt-22 l:-mt-30;
        }
    }

}
