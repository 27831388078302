.logo {

    width: 110px;
    height: 67px;

    @screen m {
        width: 123px;
        height: 75px;
    }
    @screen lp {
        width: 150px;
        height: 92px;
    }
}
