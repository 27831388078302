.tech {

    @screen l {
        margin-top: -15vh;
    }

    &__legend,
    &__panel > * {
        @screen l {
            opacity: 0;
            transition: opacity 0.3s 0.1s;
            html.no-js &,
            [data-component-booted] & {
                opacity: 1;
            }
        }
    }

    &__label {
        transition: opacity 0.3s $easeInSine;
        .is-active & {
            opacity: 1;
        }
    }

    &__line {
        width: 3px;
        height: 34px;

        transition: transform 0.5s, height 0.5s;

        .is-active & {
            @apply bg-white;
            transform: skew(30deg, 0deg) scale(1.25, 1);
            height: 25px;
        }

        .is-adjacent & {
            height: 54px;
        }

        @screen lp {
            width: 4px;
            height: 46px;

            .is-active & {
                height: 34px;
            }

            .is-adjacent & {
                height: 72px;
            }
        }
    }

    &__nav {
        width: 26px;
        height: 26px;

        &:first-child {
            transform: scale(-1, 1);
        }

        svg {
            width: 10px;
        }

        @screen lp {
            width: 36px;
            height: 36px;
            svg {
                width: 14px;
            }
        }
    }
}
