.btn {

  height: 39px;

  &--small {
    height: 33px;
    @screen lp {
      height: 39px;
    }
  }

  &--medium {
    @screen l {
      height: 33px;
    }
    @screen lp {
      height: 39px;
    }
  }

  &--big {
    @screen lp {
      height: 42px;
    }
  }

  &-mono {
    height: 31px;
  }

  // Submit buttons are special
  &[type="submit"] {
    min-width: 100px;
    height: 57px;
    @screen l {
      height: 51px;
    }
    @screen lp {
      height: 57px;
    }
  }

  // Disabled state
  &[aria-disabled="true"] {
    opacity: 0.5;
  }

}

// Outline buttons
.btn--outline:not([class*="hover:"]) {
  @apply hover:bg-light;
  .bg-dark & {
    @apply hover:bg-darkish;
  }
}

// Filled buttons
.btn--filled:not([class*="hover:"]) {
  @apply bg-dark text-white border-dark hover:bg-black hover:border-black;
  .bg-dark & {
    @apply bg-white text-dark border-white hover:bg-darkish hover:text-white;
  }
}
