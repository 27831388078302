/* ==============================
    Basic/generic global styles
 ============================== */

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

button, a {
    touch-action: manipulation;

    &[disabled] {
        cursor: default;
    }
}

[hidden]:not(:target) {
    display: none !important;
}


input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

select {
    appearance: none;
}

::-moz-selection {
    background: #FFE31C;
    @apply text-dark;
}

::selection {
    background: rgba(#FFE31C, 0.99);
    @apply text-dark;
}

// Wasn't completely sure this was safe to delete
//.ratio {
//    padding-bottom: calc(100% / (var(--ratio-p)));
//    @media (orientation: landscape) {
//        padding-bottom: calc(100% / (var(--ratio-ls)));
//    }
//}

h1 br {
    display: none;
    @screen sp {
        display: inline-block;
    }
}

a {
    text-underline-offset: 2px;
}

// Make sure that form elements, when focused via :target, have a little top padding relative to the viewport
input:not([type="hidden"]),
textarea,
select,
[aria-haspopup="listbox"],
[data-receipt] {
    scroll-margin-top: 80px;
}

[data-receipt] {
    outline: none !important;
}

// Error state for forms elements
:root .error {
    input, textarea {
        @apply text-error border-error;
    }
}

:root [data-type="dropdown"].error {
    &, *:not([role="option"]):not([role="listbox"]) {
        @apply text-error border-error;
    }
}

:root [data-type="checkboxes"].error {
    &, * {
        @apply border-error;
    }
}

ul.errors {
    li {
        margin: 0 0 0 5px;
    }
}

a.skip:not(:focus) {
    @apply sr-only;
}
