.header {

  &__burger {

    width: 28px;
    height: 13px;
    margin-top: 33px;

    @screen m {
      margin-top: 28px;
    }

    @screen l {
      margin-top: 42px;
    }

    @screen lp {
      width: 35px;
      height: 16px;
    }

    span {
      height: 4px;

      &:last-child {
        top: 9px;
        @screen lp {
          top: 12px;
        }
      }
    }

    &[aria-expanded="true"] {
      span {
        @apply bg-dark;
      }
      span:last-child {
        opacity: 0;
      }
    }

    @at-root {
      .no-js :target & a:first-child {
        display: block;
      }
      .no-js :target & a:last-child {
        display: none;
      }
    }

  }

  &__menu {
    html.no-js &:target &-items {
      display: block !important;
    }
  }
}
